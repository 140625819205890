<div class="form-container">
    <form [formGroup]="contactForm" (ngSubmit)="enviar()">
        <div>
            <h5 class="title">{{ titulo }}</h5>
        </div>
        <div>
            <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                <mat-label>Nombre Completo</mat-label>
                <input
                    matInput
                    formControlName="nombre_completo"
                    [disabled]="formSubmitted || enviando"
                />
                @if ( f['nombre_completo'].errors) {
                    <mat-error> Campo obligatorio. </mat-error>
                }
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                <mat-label>Correo Electrónico</mat-label>
                <input
                    matInput
                    formControlName="email"
                    type="email"
                    [disabled]="formSubmitted || enviando"
                />
                @if ( f['email'].errors) {
                    <mat-error>
                        Campo obligatorio.
                    </mat-error>
                }
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                <mat-label>Teléfono</mat-label>
                <input
                    type="phone"
                    matInput
                    formControlName="telefono"
                    [disabled]="formSubmitted || enviando"
                />
                @if (formSubmitted && f['telefono'].errors?.['required']) {
                    <mat-error> El teléfono es obligatorio. </mat-error>
                }
                @if(f['telefono'].errors?.['invalidTelefono']){
                    <mat-error> Solo dígitos numéricos. </mat-error>
                }
            </mat-form-field>

            @if (cargarCv == false) {
                <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                    <mat-label>Empresa</mat-label>
                    <input
                        matInput
                        formControlName="empresa"
                        [disabled]="formSubmitted || enviando"
                    />
                    @if ( f['empresa'].errors) {
                        <mat-error> Campo obligatorio. </mat-error>
                    }
                </mat-form-field>
            }
            @if (cargarCv == true) {
                <div class="file">
                    <button
                        type="button"
                        class="cargar-cv-button"
                        mat-flat-button
                        (click)="fileInput.click()"
                        [disabled]="formSubmitted || enviando"
                    >
                        Cargar CV
                    </button>
                    <input
                        hidden
                        (change)="onFileSelected($event)"
                        #fileInput
                        type="file"
                        name="cv"
                    />
                    @if (fileName) {
                        <div class="file-name">
                            Archivo cargado
                        </div>
                    }
                </div>
            }
        </div>
        <div>
            <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="text-area-formField fz-18"
                
            >
                <mat-label>Mensaje</mat-label>
                <textarea
                    matInput
                    formControlName="mensaje"
                    placeholder="¿En qué rubro estás y cómo podemos ayudarte?"
                    [disabled]="formSubmitted || enviando"
                ></textarea>
                @if (f['mensaje'].errors) {
                    <mat-error> El mensaje es obligatorio. </mat-error>
                }
            </mat-form-field>
        </div>
        <div>
            <button type="submit" [disabled]="!contactForm.valid || formSubmitted || enviando" (click)="enviar()">
                @if (!formSubmitted || !enviando) {
                    <img src="img/send.svg" />
                }
                {{formSubmitted ? "Hemos recibido tu mensaje correctamente" :  "ENVIAR"}}
            </button>
        </div>
    </form>
</div>
