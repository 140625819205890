import { Component, input, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from '../api.service';

@Component({
    selector: 'app-formulario-contacto',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
    ],
    templateUrl: './formulario-contacto.component.html',
    styleUrl: './formulario-contacto.component.scss',
})
export class FormularioContactoComponent implements OnInit {
    @Input() 
    public titulo: string = 'Cotizá ahora';
    @Input() 
    public cargarCv: boolean = false;

    public contactForm!: FormGroup;
    public formSubmitted: boolean = false;
    public enviando: boolean = false;
    public selectedFile: any = null;
    public fileName: string | null = null; // Nueva variable para el nombre del archivo
    private apiUrl = '/contacto/enviar'; // Define your endpoint

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService, // Inject the ApiService
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        if (!this.cargarCv) {
            this.contactForm = this.fb.group({
                nombre_completo: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                telefono: ['', [Validators.required,this.telefonoValidator]],
                empresa: ['', Validators.required],
                mensaje: ['', Validators.required],
            });
        } else {
            this.contactForm = this.fb.group({
                nombre_completo: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                telefono: ['', [Validators.required,this.telefonoValidator]],
                cv: [null, Validators.required],
                mensaje: ['', Validators.required],
            });
        }
    }

    telefonoValidator(control: AbstractControl) {
        const telefonoRegex = /^[0-9]+$/; // Permitir solo dígitos
        const valid = telefonoRegex.test(control.value);
        return valid ? null : { invalidTelefono: true };
    }

    public async enviar() {
        if (!this.enviando) {
            const formData = this.contactForm.value;
            
            if (this.cargarCv) {
                this.apiUrl = '/talento/enviar-formulario-cv ';
            } 

            this.enviando = true;

            try {
                let response = await this.apiService.post(
                    this.apiUrl,
                    formData,
                );
                this.formSubmitted = true;
                this.contactForm.reset();
                this.contactForm.disable()
            } catch (error) {
                this.enviando = false
                console.error(
                    'Error al enviar el formulario con CV',
                    error,
                );
            }
        }
    }

    onFileSelected(event: any): void {
        const file = event.target.files[0];
        if (file) {
            this.contactForm.patchValue({
                cv: file,
            });
            this.contactForm.get('cv')?.updateValueAndValidity();
            this.fileName = file.name; // Asigna el nombre del archivo a la variable

        }
    }

    get f() {
        return this.contactForm.controls;
    }
}
